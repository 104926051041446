<template>
  <router-view></router-view>
  <notifications/>
</template>

<script lang="ts" setup>
import {watch} from 'vue';
import {useRoute} from 'vue-router';
import {Notifications} from '@/components/base/notifications';

const route = useRoute();

watch(
    () => route.meta?.title,
    () => {
      document.title = (route.meta?.title as string) || 'Admin ';
    },
    {immediate: true},
);

</script>